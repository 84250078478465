import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../layouts"
import 'uikit/dist/css/uikit.css'
// import UIkit from 'uikit'
// import Header from "../components/header/header"
// import Banner from "../components/banner/banner"
// import Paragraph from "../components/paragraph/paragraph"
// import Accordion from "../components/accordion/accordion"
// import CTAList from "../components/cta-list/ctaList"
// import Comparison from "../components/comparison/comparison"
// import Providers from "../components/provider-list/providers"
import "../styles/secondary-page.scss"

import GetImgUrl from "../utils/customFunctions"
// import ImageCarousel from "../components/image-carousel/image-carousel"
// import HtmlEditor from "../components/html-editor/html-editor"

import Paragraph from "../components/paragraph/paragraph";
import CTABanner from "../components/cta-banner/cta-banner";
import Header from "../components/header/header"
import Banner from "../components/banner/banner"
import CTAList from "../components/cta-list/ctaList"
import Comparison from "../components/comparison/comparison";


// const Header = loadable(() => import("../components/header/header"))
// const Banner = loadable(() => import("../components/banner/banner"))
// const Reviews = loadable(() => import("../components/reviews/reviews"))
// const Paragraph = loadable(() => import("../components/paragraph/paragraph"))
// const Accordion = loadable(() => import( "../components/accordion/accordion"))
// const CTAList = loadable(() => import( "../components/cta-list/ctaList"))
// const Providers = loadable(() => import(  "../components/provider-list/providers"))
// const Comparison = loadable(() => import(  "../components/comparison/comparison"))
// const ImageCarousel = loadable(() => import("../components/image-carousel/image-carousel"))
// const HtmlEditor = loadable(() => import("../components/html-editor/html-editor"))




export const query = graphql`
  query SecondaryPagesQuery($id: Int) {
    strapiSecondaryPages(strapiId: { eq: $id }) {
      Title
      slug
      PageContent
      SEOTitle
      SEODescription
      FooterDisclaimer
      MetaTags{
        MetaName
        MetaContent
      }
      Related {
        CTALabel
        CTAUrl
        CTA {
          Label
          URL
          Image {
            url
          }
        }
        Links {
          Title
          URL
        }
      }
    }
  }
`;






// markup
const SecondaryPage = ({data}) => {

    function getImgUrl(localFile___NODE){

      var imgUrl = {imgName:null, basicUrl: null, fluid: null, gatsbyImageData:null}

      data.allFile.edges.map((item, index) => {

        if(item.node.id === localFile___NODE){
          // if(item.node.publicURL.endsWith(".svg")){
          //   imgUrl.basicUrl = item.node.publicURL
          // } else {
          //   imgUrl.fluid = item.node.childImageSharp.fluid
          //   imgUrl.basicUrl = item.node.publicURL
          // }
          imgUrl.imgName = item.node.name?item.node.name:null
          imgUrl.basicUrl = item.node.publicURL?item.node.publicURL:null
          imgUrl.fluid = item.node.childImageSharp?item.node.childImageSharp.fluid:null
          imgUrl.gatsbyImageData = item.node.childImageSharp?item.node.childImageSharp.gatsbyImageData:null

          // imgUrl = item.node.publicURL
        }
        return("")
        
      })
    //   console.log(imgUrl)
      return(imgUrl)
    }
 
    var ukGrid = data.strapiSecondaryPages.Related?"uk-grid":""

    if(data.strapiSecondaryPages.Related===null){
        return (//returns the standard layout
        <Layout SEOTitle={data.strapiSecondaryPages.SEOTitle||data.strapiSecondaryPages.Title} SEODescription={data.strapiSecondaryPages.SEODescription} footerDisclaimer={data.strapiSecondaryPages.FooterDisclaimer} meta={data.strapiSecondaryPages.MetaTags}>

            {data.strapiSecondaryPages.PageContent.map((item, index) => {

                    
            switch (item.strapi_component) {

            
            case "page-components.header":
                return(
                     
                           <Header key={index}
                    title={item.Title}
                    subtitle={item.Subtitle}
                    secondarySubtitle={item.SecondSubtitle}
                    CTAText={item.CTALabel}
                    CTAUrl={item.CTAUrl}
                    CTA_nofollow={item.CTA_nofollow}
                    Disclaimer={item.Disclaimer}
                    Background={GetImgUrl(item.Background.localFile___NODE)}
                    HeaderHeight={item.HeaderHeight}
                ></Header>
                    
              
                )

        
            case "page-components.cta-banner":
                return(
                
                    <CTABanner
                    key={index}
                    text={item.Text}
                    ctaUrl={item.CTAUrl}
                    ctaLabel={item.CTALabel}
                    secondaryCtaUrl={item.SecondaryCTAUrl}
                    secondaryCtaLabel={item.SecondaryCTALabel}
                    bgColorHex={item.BackgroundColorHEX}
                    ></CTABanner>
                
                )
            
            case "page-components.comparison":
                return(
                     
                         <Comparison key={index}
                    title={item.Title}
                    step1={item.Step1}
                    step2={item.Step2}
                    step3={item.Step3}
                    image1={GetImgUrl(item.Image1.localFile___NODE)}
                    image2={GetImgUrl(item.Image2.localFile___NODE)}
                    image3={GetImgUrl(item.Image3.localFile___NODE)}
                ></Comparison>
                     
                
                )


            case "page-components.banner":
                return(
                    
                         <Banner
                    key={index}
                    text={item.Text}
                    image={GetImgUrl(item.Image.localFile___NODE)}
                    imageAlt={item.Image.alternativeText}
                    dark={item.DarkBackground}
                    title={item.Title}
                    subtitle={item.Subtitle}
                ></Banner> 
                   
               
                )

            case "page-components.paragraph":
                return(
                <Paragraph
                    key={index}
                    title={item.Title?item.Title:""}
                    text={item.Text}
                    bgColor={item.BackgroundColor||""}
                
                ></Paragraph>
                )



            case "page-components.cta-list":
                var contentsList = []
                item.CTAList.map((CTAitem, index) => {
                    var imageUrl = CTAitem.Image !== null?GetImgUrl(CTAitem.Image.localFile___NODE):""
                    contentsList.push({
                    Title: CTAitem.Title,
                    URL: CTAitem.URL,
                    URLLabel: CTAitem.URLLabel,
                    Image: imageUrl,
                    Alt: CTAitem.Image.alternativeText,
                    Description: CTAitem.Description,
                    DescriptionCharLimit: CTAitem.DescriptionCharLimit
                    })
                    return("")
                })
                return(
                     
                         <CTAList
                    key={index}
                    title={item.Title?item.Title:""}
                    subtitle={item.Subtitle}
                    contents={contentsList}
                    cardStyle = {item.CardStyle}
                ></CTAList>
               
                
                )
            

            default:
                return("")
            }



            })}
            

        </Layout>
    )
    }else{
        return (//returns the related layout
            <Layout 
                SEOTitle={data.strapiSecondaryPages.SEOTitle||data.strapiSecondaryPages.Title}
                SEODescription={data.strapiSecondaryPages.SEODescription} footerDisclaimer={data.strapiSecondaryPages.FooterDisclaimer}
                darkThemeNav={true}>
        
        
            
            <div className="secondary-split" data-uk-grid>
            
                <div className="uk-width-3-5@s">

                    {data.strapiSecondaryPages.PageContent.map((item, index) => {
            
                            
                    switch (item.strapi_component) {
            

            
                    // case "page-components.header":
                    //     return(
                    //     <Header key={index}
                    //         title={item.Title}
                    //         subtitle={item.Subtitle}
                    //         secondarySubtitle={item.SecondSubtitle}
                    //         CTAText={item.CTALabel}
                    //         CTAUrl={item.CTAUrl}
                    //         Disclaimer={item.Disclaimer}
                    //         Background={GetImgUrl(item.Background.localFile___NODE)}
                    //         // HeaderHeight={item.HeaderHeight}
                    //     ></Header>
                    //     )
            
                    case "page-components.paragraph":
                        return(
                        <Paragraph
                            key={index}
                            title={item.Title?item.Title:""}
                            text={item.Text}
                            bgColor={item.BackgroundColor||""}
                        
                        ></Paragraph>
                        )

            
                    default:
                        return("")
                    }
            
            
            
                    })}
                </div>

                <div className="uk-width-2-5@s related-col">
                    <div className="related-col-title">
                    Related
                    </div>
                    <div className="related-links">
                        <ul>
                            {data.strapiSecondaryPages.Related?data.strapiSecondaryPages.Related.Links.map((item, index) => {
                        
                                return(
                                    <li key={index}>
                                        <Link to={item.URL}>{item.Title}</Link>
                                    </li>
                                )
                                
                        
                            }):"not found"}
                        </ul>
                    </div>
                    <div className="related-cta">
                        <div class="related__cta">
                            <a className="cta" href={data.strapiSecondaryPages.Related?data.strapiSecondaryPages.Related.CTAUrl:""}>{data.strapiSecondaryPages.Related.CTALabel}</a>
                        </div>
                    </div>
                    <div className="related-cta-cards">
                        <ul>
                            {data.strapiSecondaryPages.Related?data.strapiSecondaryPages.Related.CTA.map((item, index) => {
                        
                                return(
                                    <li>
                                        <Link to={item.URL} key={index}>
                                            <img src={item.Image.url}></img>
                                            {item.Label}
                                            
                                        </Link>
                                    </li>
                                )
                                
                        
                            }):"not found"}
                        </ul>
                    </div>
                </div>

            </div>

            {data.strapiSecondaryPages.PageContent.map((item, index) => {
        
                        
                switch (item.strapi_component) {

                

                case "page-components.comparison":
                    return(
                    <Comparison key={index}
                        title={item.Title}
                        step1={item.Step1}
                        step2={item.Step2}
                        step3={item.Step3}
                        image1={GetImgUrl(item.Image1.localFile___NODE)}
                        image2={GetImgUrl(item.Image2.localFile___NODE)}
                        image3={GetImgUrl(item.Image3.localFile___NODE)}
                    ></Comparison>
                    )


                case "page-components.banner":
                    return(
                    <Banner
                        key={index}
                        text={item.Text}
                        image={GetImgUrl(item.Image.localFile___NODE)}
                        imageAlt={item.Image.alternativeText}
                        dark={item.DarkBackground}
                        title={item.Title}
                        subtitle={item.Subtitle}
                    ></Banner>
                    )

            


                case "page-components.cta-list":
                    var contentsList = []
                    item.CTAList.map((CTAitem, index) => {
                        var imageUrl = CTAitem.Image !== null?GetImgUrl(CTAitem.Image.localFile___NODE):""
                        contentsList.push({
                        Title: CTAitem.Title,
                        URL: CTAitem.URL,
                        Image: imageUrl,
                        Alt: CTAitem.Image.alternativeText,
                        Description: CTAitem.Description
                        })
                        return("")
                    })
                    return(
                    <CTAList
                        key={index}
                        title={item.Title?item.Title:""}
                        subtitle={item.Subtitle}
                        contents={contentsList}
                        cardStyle = {item.CardStyle}
                    ></CTAList>
                    )

                default:
                    return("")
                }



                })}
                
        
            </Layout>
        )

    }
}

export default SecondaryPage